import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/pagination/pagination.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/pagination" storybook="https://workday.github.io/canvas-kit/?path=/story/components-navigation-pagination--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=4410-210&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1ad14b5fd5b879942d1cd6e29b41ebe3/536c7/component-anatomy-pagination.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.054054054054056%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Pagination component with annotation markers.",
              "title": "Image of a Pagination component with annotation markers.",
              "src": "/static/1ad14b5fd5b879942d1cd6e29b41ebe3/50383/component-anatomy-pagination.png",
              "srcSet": ["/static/1ad14b5fd5b879942d1cd6e29b41ebe3/1efb2/component-anatomy-pagination.png 370w", "/static/1ad14b5fd5b879942d1cd6e29b41ebe3/50383/component-anatomy-pagination.png 740w", "/static/1ad14b5fd5b879942d1cd6e29b41ebe3/536c7/component-anatomy-pagination.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Page Numbers`}</strong>{`: Each page has its own page number. All page numbers inherit styling and
interactions from our `}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Icon Only Primary Button Variant`}</a>{`.`}</li>
        <li parentName="ol"><strong parentName="li">{`Navigational Controls`}</strong>{`: Allow users to navigate through available pages. These controls
inherit styling and interactions from our
`}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Icon Only Tertiary Button`}</a>{`. Previous/Next uses Small Chevron System
Icon and First/Last uses Chevron x2 Small System Icon.`}</li>
        <li parentName="ol"><strong parentName="li">{`Input w/ Label`}</strong>{`: Shows a box adjacent to the pagination bar where a page can be entered and is
submitted when 'Enter' key is pressed. Enables users to jump to specific page. Input uses the
Canvas Text Input with browser-specific numeric input override.`}</li>
        <li parentName="ol"><strong parentName="li">{`Additional Details`}</strong>{`: Communicates the number of pages and total number of items. Page numbers
are separated by an en dash. The word “pages” is customizable.`}</li>
      </ol>
      <p>{`Pagination is a highly configurable component so we’ve intentionally built each piece of this
component separately so you may compose it in a variety of ways.`}</p>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Pagination is a highly configurable navigation component composed of the following elements:`}
          <ul parentName="li">
            <li parentName="ul"><strong parentName="li">{`Page Numbers:`}</strong>{` Provide context about what page the user is on in relation to other pages.`}</li>
            <li parentName="ul"><strong parentName="li">{`Page Navigation:`}</strong>{` Navigational controls allow users to navigate back or forward through
pages. If applicable, they are also able to jump to the first or last page.`}</li>
            <li parentName="ul"><strong parentName="li">{`Numeric Input:`}</strong>{` Enables users to jump to a specific page.`}</li>
            <li parentName="ul"><strong parentName="li">{`Label:`}</strong>{` Communicates the number of pages and total number of items. The word “items” is
customizable.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Although the component is typically placed below the corresponding content or data, there is
flexibility on placement depending on your use case.`}</li>
        <li parentName="ul">{`Pagination is typically used with `}<a parentName="li" {...{
            "href": "/components/containers/table"
          }}>{`Tables`}</a>{`.`}</li>
        <li parentName="ul">{`In responsive experiences, Pagination condenses to its most basic elements (Left/Right Controls,
Current Page Number).`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`To divide large quantities of data or content into chunks.`}</li>
        <li parentName="ul">{`To improve the loading performance of a system.`}</li>
        <li parentName="ul">{`To make user comprehension of data/content less overwhelming.`}</li>
        <li parentName="ul">{`To enable all users to navigate to through pages or locate a specific page number.`}</li>
        <li parentName="ul">{`To show how many pages of content there are and how many results have been returned.`}</li>
      </ul>
      <h3 {...{
        "id": "best-practices"
      }}>{`Best Practices`}</h3>
      <ul>
        <li parentName="ul">{`Display at least one way for users to navigate through pages. If possible, provide more than one
option (eg. Previous/Next controls `}<em parentName="li">{`and`}</em>{` a Numeric Input).`}</li>
        <li parentName="ul">{`Display the Current Page to provide awareness of location in relation to the other pages.`}</li>
        <li parentName="ul">{`If feasible, display additional details (number of pages and total number of items) to communicate
even more context about the content or data the user is paging through.`}</li>
      </ul>
      <p>{`Each element of Pagination has been built separately so you may compose it in a variety of ways. How
you decide to put this component together will likely depend on the reality of the technical
constraints that exist and your user needs.`}</p>
      <p>{`Below are some examples of how you can configure Pagination based on screen size or technical
considerations. These recommendations are not exhaustive.`}</p>
      <h4 {...{
        "id": "based-on-screen-size"
      }}>{`Based on Screen Size:`}</h4>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b524676320fbb913d28487fbb562ca10/c0566/pagination-screen-size.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "42.972972972972975%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen Size diagrams for Pagination",
              "title": "Screen Size diagrams for Pagination",
              "src": "/static/b524676320fbb913d28487fbb562ca10/50383/pagination-screen-size.png",
              "srcSet": ["/static/b524676320fbb913d28487fbb562ca10/1efb2/pagination-screen-size.png 370w", "/static/b524676320fbb913d28487fbb562ca10/50383/pagination-screen-size.png 740w", "/static/b524676320fbb913d28487fbb562ca10/c0566/pagination-screen-size.png 1544w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`For smaller screens, it’s recommended to show page navigation controls with no more than 3 pages.`}</li>
        <li parentName="ul">{`For larger screens, you can show up to 5 pages, both types of page navigation controls, and a
numeric input with additional details.`}</li>
      </ul>
      <h4 {...{
        "id": "based-on-technical-considerations"
      }}>{`Based on Technical Considerations:`}</h4>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/85d9b1c278ad305881c3261f9679e41f/6a5c3/pagination_tech-considerations.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.75675675675676%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Technical Considerations for pagination",
              "title": "Technical Considerations for pagination",
              "src": "/static/85d9b1c278ad305881c3261f9679e41f/50383/pagination_tech-considerations.png",
              "srcSet": ["/static/85d9b1c278ad305881c3261f9679e41f/1efb2/pagination_tech-considerations.png 370w", "/static/85d9b1c278ad305881c3261f9679e41f/50383/pagination_tech-considerations.png 740w", "/static/85d9b1c278ad305881c3261f9679e41f/6a5c3/pagination_tech-considerations.png 1646w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`If your API is unable to return a total page number, we recommend showing the Previous/Next page
navigation controls, the Current Page Number, and up to 4 other Page Numbers (if possible).`}</li>
        <li parentName="ul">{`If your API is able to return a known number of pages, composability options are endless. You can
essentially pair any combination of elements together to create a solution that is best for your
use case.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-pagination-impacts-the-accessible-experience"
      }}>{`How Pagination Impacts the Accessible Experience`}</h3>
      <p>{`Pagination controls can be a more accessible alternative to infinitely scrolling content which may
introduce accessibility barriers for some users. Respecting users’ preference for how much content
they’d like to consume at one time sets the stage for more accessible UI and reducing cognitive
load.`}</p>
      <p>{`For screen readers, users are going to expect a confirmation, or validation, that the screen has
been updated after interacting with Pagination controls. This has been historically accomplished by
HTTP requests that refresh the page and reset users to the top of the screen. Today, this can be
accomplished by setting keyboard focus to a target above the updated content, or by leveraging ARIA
live region status updates.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Pagination component must have a focus indicator that is highly visible against the background
and against the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for
more information.`}</p>
      <p>{`Pagination must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus each button in the Pagination component`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activate the focused button`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Pagination must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`Pagination is a navigation landmark that can be distinguished from other navigation landmarks
provided on a page.`}</li>
        <li parentName="ul">{`Which page is actively selected in Pagination, out of the total number of pages available.`}</li>
        <li parentName="ul">{`The screen content has been updated after selecting a different page.`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Write the accessible names of each icon button in Pagination`}</li>
        <li parentName="ul">{`Write the label for the text input in the pagination component`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Use `}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`accessible tooltips`}</a>{` to show the icon button’s name for everyone.
The `}<inlineCode parentName="li">{`Tooltip`}</inlineCode>{` component will apply the text automatically as an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` for the icon button.`}</li>
        <li parentName="ul">{`When not using the `}<inlineCode parentName="li">{`Tooltip`}</inlineCode>{` component, assigning an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string is required for icon-only
buttons and the GoTo Form text input.`}</li>
        <li parentName="ul">{`Set keyboard focus to a target at the top of the updated content after users interact with
Pagination controls.`}</li>
        <li parentName="ul">{`Set `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` property on the GoTo Form text input, referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the
GoTo Form label.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Pagination container must be a `}<inlineCode parentName="li">{`<nav>`}</inlineCode>{` element with an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string
describing Pagination context. For example, “Search results” when paginating a list of search
results.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Pagination must be structured as a semantic ordered list of button
elements.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The Next and Previous page controls must use the `}<inlineCode parentName="li">{`aria-disabled`}</inlineCode>{` property
when those controls are unavailable.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The currently selected page control must use the `}<inlineCode parentName="li">{`aria-current`}</inlineCode>{` property.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The Additional Details text below Pagination must use the `}<inlineCode parentName="li">{`role=”status”`}</inlineCode>{`,
`}<inlineCode parentName="li">{`aria-live=”polite”`}</inlineCode>{`, and `}<inlineCode parentName="li">{`aria-atomic=”true”`}</inlineCode>{` properties for real-time updates to screen readers.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5ea924f2d033a18952638f88844e9dbc/f868f/pagination-content.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "28.37837837837838%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Content",
              "title": "Content",
              "src": "/static/5ea924f2d033a18952638f88844e9dbc/50383/pagination-content.png",
              "srcSet": ["/static/5ea924f2d033a18952638f88844e9dbc/1efb2/pagination-content.png 370w", "/static/5ea924f2d033a18952638f88844e9dbc/50383/pagination-content.png 740w", "/static/5ea924f2d033a18952638f88844e9dbc/f868f/pagination-content.png 1382w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`If customizing the label of the total number of items, use a word that accurately describes the
items that are being shown.`}</li>
        <li parentName="ul">{`Placement of the label is flexible.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      